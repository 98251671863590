<template>
  <div>
    <el-row> 
      <el-col :span="24" class="infoTit">
       <span class="brandName">{{goodsinfo.brandName}} </span>
        <!-- {{ goodsinfo.name }} -->
         <span v-if="goodsinfo.alias==''||goodsinfo.alias==null">{{ goodsinfo.name }}  </span>
         <span v-else>{{ goodsinfo.alias }}  </span>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24" class="infoMsg">
        <span>本套餐包含：{{ subTitle }}</span>
          <!-- <p v-for="(item, index) in goodsinfo.subTitle.split('；')" :key="index" >{{ item }}</p> -->
      </el-col>
    </el-row>
    <el-row class="infoBox">
     
      <el-col :span="24" class="infoActivity">
        <span class="infoKey">活动说明:</span>
        <!-- <span class="infoTag">{{goodsinfo.description}}</span> -->
        <span class="infoDes">{{goodsinfo.description}}</span>
      </el-col>
      <!-- <el-col :span="24" class="infoActivity">
        <span class="infoKey"></span>
        <span class="infoTag">免运费</span>
        <span class="infoDes">该商品免运费</span>
      </el-col> -->
    </el-row>
    <el-row  :span="24"  class="infoParameter">
       <el-col :span="6">
        <span class="infoKey" style="text-indent: 30px;">套餐价：</span
        ><span class="price">
           {{ goodsinfo.auditUserStatus===0?"***":goodsinfo.comboPrice  | capitalize }}
           </span>
           <del>{{subTotal}}</del>
          <!-- {{ goodsinfo.comboPrice==="***"?"会员可见":goodsinfo.comboPrice }} -->
        </el-col>
      <el-col :span="6">
        <span class="infoKeys" >限购<span class="price">{{goodsinfo.comboUserTotal}}</span>套，已购<span class="price">{{goodsinfo.userUsage}}</span>套</span>
      </el-col>
      <el-col :span="6">
        <!-- {{goodsinfo.comboTotal-(goodsinfo.comboUsage>0?goodsinfo.comboUsage:0)}} -->
        <span class="infoKey" style="text-indent: 30px;">库存:</span>
        <!-- <span>{{goodsinfo.quantity==-1 ||goodsinfo.quantity==0 ?'备货中':goodsinfo.quantity>1000?"充足":goodsinfo.quantity+goodsinfo.unit}}</span> -->
          <span class="msg" v-if="goodsinfo.comboTotal-(goodsinfo.comboUsage>0?goodsinfo.comboUsage:0)<=0">备货中</span>
          <span class="msg" v-if="goodsinfo.comboTotal-(goodsinfo.comboUsage>0?goodsinfo.comboUsage:0)>1000">充足</span>
          <span class="msg" v-if="goodsinfo.comboTotal-(goodsinfo.comboUsage>0?goodsinfo.comboUsage:0)<=1000 && goodsinfo.comboTotal-(goodsinfo.comboUsage>0?goodsinfo.comboUsage:0)>0">{{Number(goodsinfo.comboTotal-(goodsinfo.comboUsage>0?goodsinfo.comboUsage:0))}}套</span>
      </el-col>
      
    </el-row>
    <el-row class="infoNumBox">
      <el-col :span="24">
        <span class="infoKey">采购数量:</span>
        <el-input-number
          v-model="num"
          @change="handleChange"
          :min="stepNum"
          :max="Number(goodsinfo.comboUserTotal-goodsinfo.userUsage)"
          :step="stepNum"
          step-strictly
        ></el-input-number>
      </el-col>
    </el-row>
    <div class="infoBtns">
      <div :span="24">
        <span class="infoKey"></span>
        <button :disabled="Number(goodsinfo.comboTotal-goodsinfo.comboUsage)< 1 || Number(goodsinfo.comboUserTotal-goodsinfo.userUsage)<1" :class="Number(goodsinfo.comboTotal-goodsinfo.comboUsage)< 1 || Number(goodsinfo.comboUserTotal-goodsinfo.userUsage)<1?'grey shopBtn':'shopBtn'"  @click="immediateBuy(goodsinfo)">
          立即购买
        </button>
        <button  v-if="goodsinfo.becomeOrder!==1" :disabled="Number(goodsinfo.comboTotal-goodsinfo.comboUsage)< 1 || Number(goodsinfo.comboUserTotal-goodsinfo.userUsage)<1" :class="Number(goodsinfo.comboTotal-goodsinfo.comboUsage)< 1 || Number(goodsinfo.comboUserTotal-goodsinfo.userUsage)<1?'grey addBtn':'addBtn'" @click="addCartFun(goodsinfo)">加入购物车</button>
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped>
@import "~style/index.less";
.info {
  overflow: hidden;
  .infoTit {
    font-size: 20px;
    line-height: 30px;
    color: #333;
    font-weight: bold;
    .brandName{
      font-weight: bolder;
      color: #333;
    }
    .tag{
        width:42px;
        text-align: center;
          height: 20px;
          // padding: 0 5px;
          line-height: 19px;
          margin-top: 2px;
          border-width: 1px;
          font-size:14px;
    border-style: solid;
    box-sizing: border-box;
    white-space: nowrap;
    display: inline-block;
        margin-right:3px;
          &.titColor{
            color:#fff
          }
           &.radiusBox{
            border-radius:50%;
          }
          &.bgRed{
             background-color: #DD1F1F;
              border-color: #DD1F1F;
          }
          &.bgBlue{
              background-color: #409eff;
              border-color: #409eff;
          }
          &.bgGreen{
            background-color:  #52C41A;
              border-color:  #52C41A;
          }
          &.bgOrange{
             background-color:#FF7A45;
               border-color: #FF7A45;
          }
    }
  }
  .infoMsg {
    color: #FFD303;
    font-size: 14px;
    line-height: 30px;
    font-weight: bold;
        margin-top: 5px;
  }
  span.infoKey {
    width: 86px;
    text-align: left;
    display: inline-block;
    color: #666;
    font-size: 14px;
  }
  span.infoKeys {
    text-align: left;
    display: inline-block;
    color: #666;
    font-size: 14px;
  }
  .downBtn{
    cursor: pointer;
    &:hover{
         color: #FFD303;
    }
  }
  .infoNumBox {
    padding: 0px 10px;
    margin-bottom: 25px;
  }
  .infoBtns {
    padding: 0px 10px;
    z-index: 9999;
    position: relative;
    margin-bottom: 20px;
    button {
      width: 150px;
      height: 42px;
      text-align: center;
      line-height: 42px;
      font-size: 15px;
      font-weight: bold;
      margin-top: 8px;
      border-radius: 0;
      padding: 0;
      &.addBtn {
        background: #FFD303;
        color: #000;
        margin-left: 20px;
        border: 1px solid #FFD303;
        outline: none;
      }
      &.shopBtn {
        background: #ff3333;
        color: #fff;
        border: 1px solid #ff3333;
        outline: none;
      }
      &.grey{
         background: #d4d2cf;
          color: #333;
          border: 1px solid #d4d2cf;
      }
    }
  }
  .infoParameter {
    padding: 0px 10px 25px;
    border-bottom: 1px solid #f6f6f6;
    margin-bottom: 20px;
    color: #333;
    
    del{
      color: #ccc;
      font-size: 13px;
    }
    span.price {
      color: #f43c38;
      font-size: 16px;
      font-weight: bold;
      b {
        font-size: 12px;
      }
    }
    span.msg{
        color: #666;
        font-size: 13px;
        padding-left:10px;
      }
    div{
      float: left;
      width: 50%;
      
       margin-bottom: 15px;
        &.infoActivity {
        margin-bottom: 12px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    // .el-col {
    //   margin-bottom: 15px;
     
    // }
  }
  .infoBox {
    background: #f6f6f6;
    padding: 20px 10px 0 10px;
    font-size: 14px;
    color: #999;
    line-height: 20px;
    margin-bottom: 25px;
    margin-top:10px;
    .el-col {
      margin-bottom: 25px;
      &.infoActivity {
        margin-bottom: 12px;
      }
      &:last-child {
        margin-bottom:25px;
      }
    }

    
    span.infoTag {
      border: 1px solid #FFD303;
      color: #FFD303;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      padding: 0 10px;
      margin: 0 10px;
    }
    span.infoDes {
      color: #666;
    }
  }
}
</style>
<script>
import {AddCart} from "api/product.js"
import { mapState,mapMutations,mapGetters} from "vuex";
export default {
  name: "GoodsInfo",
  data() {
    return {
      stepNum:1,
      num:1,
      promotionMap:[],
      subTitle:'',
      subTotal:0
    };
  },
  props: {
    goodsinfo: {
      type: Object,
    },
  },
  components: {},
  computed: {
    //计算属性可实时更新购物车数量
   CarGoodsNum(){
     return this.$store.getters.CarGoodsNum
   },
    ...mapState({
      goodsnum: state => state.carGoodsNum,
    })
  },
  methods: {
    
     ...mapMutations({
      setCarGoodsNum: "setCarGoodsNum",
    }),
     ...mapGetters({
        typeQuantity:"typeQuantity"
    }),
    handleChange() {
      // value
    },
   addCartFun(data) {
       if(this.goodsinfo.price==="***"){
          this.$message({
              message:'请点击顶部右侧【手机商城】，扫码二维码下载商城App，上传认证信息',
              type:"warning"
          })
          return false
      }
      // {"isRecentExpiration":0,"quantity":10,"productId":1}
      const addcartDate=[];
      addcartDate.push({
        isRecentExpiration:0,
        quantity:this.num,
        productId:data.id,
        goodNum:'',
        cartType:1,
      })
      // 加入购物车接口
      AddCart(addcartDate).then((data)=>{
        if(data.data.code==200){
            // 提示信息
            this.$notify({
              title: "加入购物车",
              message: "成功",
              type: "success",
              duration: 1000,
            });

            let count=this.num
            this.setCarGoodsNum(count);
        }else{
          this.$notify({
              title: "提示信息",
              message: data.data.msg,
              type: "error",
              duration: 1000,
            });
        }
      })
    },
    immediateBuy:function(data) {

       if(this.goodsinfo.price==="***"){
          this.$message({
              message:'未认证用户不能购买',
              type:"warning"
          })
          return false
      }

      // becomeOrder  0否 1是
      if(data.becomeOrder===1){
        // 如果存在becomeOrder ，则直接下单，单独成单
          let datailsArr=[];
          datailsArr.push({
              goodsCount:this.num,
              goodsId:data.id,
              isRecentExpiration:0,
              goodsType :6,
              originalPrice:data.comboPrice
            })
          let datas={ 
            details: datailsArr,
            orderType:5,
            promotionId:this.goodsinfo.id,
            
          }
          sessionStorage.setItem('comBo',JSON.stringify(datas))
          // 单独成单，跳订单结算,query:{ids:moreIds}}
          this.$router.push({ name: "GoodsAmount",query:{id:"combo"}});
      }else{
        // 走加入购物车

            const addcartDate=[];
            addcartDate.push({
              isRecentExpiration:0,
              quantity:this.num,
              productId:data.id,
              goodNum:'',
              cartType:1,
            })
            // 加入购物车接口
            AddCart(addcartDate).then((data)=>{
              if(data.data.code==200){
                  // 提示信息
                  this.$notify({
                    title: "加入购物车",
                    message: "成功",
                    type: "success",
                    duration: 1000,
                  });
                  let count=this.num
                  this.setCarGoodsNum(count);
                  this.$router.push({ name: "Cart" });

              }else{
                this.$notify({
                    title: "提示信息",
                    message: data.data.msg,
                    type: "error",
                    duration: 1000,
                  });
              }
            })
          }

      }

  },
  mounted() {
  },
  watch: {
    goodsinfo: function() {
    },
  },
  created() {
    // console.log(this.goodsinfo.productList)
    this.goodsinfo.productList.forEach(item=>{
      this.subTitle+=item.name+item.perQuantity+item.unit+','
    })
    this.goodsinfo.productVOList.forEach(item=>{
      this.subTotal+=item.originalPrice*item.minOrderNum
    })
    this.subTitle=this.subTitle.substring(0,this.subTitle.length-1)
    this.num=1
    this.promotionMap=this.goodsinfo.promotionMap
  
  },
};
</script>
